/**
 * Domine Font.
 * Serif font.
 */
@font-face {
  font-family: 'Sanchez';
  src: local('Sanchez'),
    url(./../assets/fonts/Sanchez.ttf) format('truetype');
}

@font-face {
  font-family: 'Sofia';
  src: local('Sofia'),
    url(./../assets/fonts/Sofia-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Petit Formal Script';
  src: local('Petit Formal Script'),
    url(./../assets/fonts/PetitFormalScript-Regular.ttf) format('truetype');
}


/**
 * PTRegular.
 */
@font-face {
  font-family: 'PTRegular';
  src: local('PTRegular'),
    url(./../assets/fonts/PTRegular.ttf) format('truetype');
}

/**
 * SourceCodePro.
 */
@font-face {
  font-family: 'SourceCodePro';
  src: local('SourceCodePro'),
    url(./../assets/fonts/SourceCodePro.ttf) format('truetype');
}
